.mApp {
    text-align: center;
  }
  
  .mApp-logo {
    height: 30vmin;
    pointer-events: none;
  }
  
  .mApp-header {
    background-color: #1F1E1C;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .mmilktea {
    font-size: 30px;
    font-weight: 'regular';
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .mcomingsoon {
    font-size: 20px;
    font-weight: 'light';
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
  }
  
  .memailcontainer {
    background-color: #DEC6A4;
    padding-top: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    margin-top: 0px;
    margin-right: 40px;
    margin-bottom: 0px;
    margin-left: 40px;
    border-radius: 20px;
    border: 5px solid white;
  }
  
  .memailinput {
    background-color: #1F1E1C;
    font-size: 20px;
    border-radius: 20px;
    border: none;
    color: white;
    text-align: center;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-color: white;
  }

  .memailsubtext1 {
    color: black;
    margin-top: 14px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 15px;
  }
  
  .memailsubtext2 {
    color: black;
    margin-top: 3px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 15px;
  }