.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: #1F1E1C;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.milktea {
  font-size: 45px;
  font-weight: 'regular';
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.comingsoon {
  font-size: 35px;
  font-weight: 'light';
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 30px;
  margin-left: 0px;
}

.emailcontainer {
  background-color: #DEC6A4;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  margin-top: 0px;
  margin-right: 40px;
  margin-bottom: 0px;
  margin-left: 40px;
  border-radius: 20px;
  border: 5px solid white;
}

.emailinput {
  background-color: #1F1E1C;
  font-size: 45px;
  border-radius: 20px;
  border: none;
  color: white;
  text-align: center;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-color: white;
}

::placeholder {
  color: white;
  opacity: 0.8;
}

.emailsubtext1 {
  color: black;
  margin-top: 17px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.emailsubtext2 {
  color: black;
  margin-top: 3px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}